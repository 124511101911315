<!--
 * @Description: 前端类目
 * @Author: 琢磨先生
 * @Date: 2022-05-13 11:42:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-01 16:41:27
-->


<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table
      v-loading="loading"
      :data="tableData"
      :border="true"
      row-key="id"
    >
      <el-table-column label="名称" prop="name" width="200"></el-table-column>
      <!-- <el-table-column label="编号" width="80" prop="id"></el-table-column> -->
      <el-table-column label="icon" width="120">
        <template #default="scope">
          <el-image
            style="width: 80px; height: 80px"
            :src="scope.row.icon"
            v-if="scope.row.icon"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="停用" width="180">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        prop="sequence"
        width="100"
      ></el-table-column>
      <el-table-column label="关联后端类目" min-width="400">
        <template #default="scope">
          <el-tag
            v-for="item in scope.row.categories"
            :key="item.value"
            style="margin-right: 20px;margin-bottom: 10px;"
            >{{ item.full_name }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <!-- <el-button type="text" size="small" @click="onConnection(scope.row)"  v-if="scope.row.father_id"
            >关联品类</el-button
          > -->
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-front
    :item="current"
    :groups="tableData"
    :reload="loadData"
  ></edit-front>
</template>

<script>
import EditFront from "./edit_front";

export default {
  components: {
    EditFront,
  },
  data() {
    return {
      loading: false,
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$http.post("/admin/v1/category/front").then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("/admin/v1/category/front/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>